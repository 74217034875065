import * as React from "react"
import { Link } from "gatsby"
import SbEditable from "storyblok-react"

import useStoryblok from "../lib/storyblok"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import DynamicComponent from "../components/DynamicComponent"
import { StyledWrapper } from "../components/Layout/styles"

const NotFoundPage = ({ location }) => {
  let components = null
  let story = useStoryblok(null, location)

  // if (story && story.content) {
  //   console.log("story", story)
  //   console.log("story.content", story.content)
  //   console.log("story.content._uid", story.content._uid)
  //   // components = () => {
  //   //   return <DynamicComponent blok={story.content} key={story.content._uid} />
  //   // }
  //   components = story.content.map(blok => {
  //     return <DynamicComponent blok={blok} key={blok._uid} />
  //   })
  // }

  return (
    <Layout>
      <SbEditable content={story ? story.content : false}>
        <StyledWrapper>
          <h1>{story ? story.content.title : "Not Found"}</h1>
          {components}
        </StyledWrapper>
      </SbEditable>
    </Layout>
  )
}

export default NotFoundPage
